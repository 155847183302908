import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { navigate } from 'gatsby';
import { Button, ButtonType } from '../../Common/Button';
import { TInsight } from '../../../pages/insights';

const InsightBox = styled.div(() => [tw`flex flex-col mb-10 md:mb-14`]);

const InsightImage = styled.img(() => [
  tw`flex justify-center items-center self-start w-full h-[190px] md:h-[300px] rounded-[10px] cursor-pointer object-cover overflow-hidden col-span-2 md:col-span-1`
]);

const InsightDetail = styled.div(() => [tw`flex flex-col justify-between col-span-2 md:col-span-1 mt-4`]);

const PublishedRow = styled.div(() => [tw`flex flex-row flex-wrap items-center mb-4 md:mb-[18px]`]);

// const AuthorDisplay = styled.img(() => [tw`w-6 h-6 rounded-full mr-2`]);

const PublishedDetail = styled.p(() => [tw`text-[14px] font-normal text-orange mr-2 mb-0`]);

const Dot = styled.div(() => [tw`w-1 h-1 bg-orange mr-2 rounded-full`]);

const ContentType = styled.p(() => [tw`text-base font-semibold text-ociDarkGrey mb-1`]);

const Title = styled.h4(() => [tw`font-semibold text-lg text-spaceGray text-left mt-0 cursor-pointer mb-1`]);

const Detail = styled.span(() => [tw`font-normal text-[#283941] text-sm md:text-[16px] leading-6 cursor-pointer mb-1 line-clamp-3`]);

interface Props {
  className?: string;
  data: TInsight;
}

const checkUnd = (value: any) => {
  if (!value || value === '') {
    return false;
  }
  return true;
};

const formatDate = (dateTime: Date) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if (dateTime) {
    const month = months[dateTime.getMonth()];
    return `${month} ${dateTime.getDate()} ${dateTime.getFullYear()}`;
  }
  return undefined;
};

const InsightCard: React.FC<Props> = ({ className, data }) => {
  return (
    <InsightBox className={className}>
      <InsightImage onClick={() => navigate(`/insights/${data.node.client}`)} src={data.node.thumbnail} />
      <InsightDetail>
        <div className="mb-4">
          <PublishedRow>
            {/* {checkUnd(data.node.profile) && <AuthorDisplay src={data.node.profile} />} */}
            {checkUnd(data.node.author) && (
              <>
                <PublishedDetail>{data.node.author} </PublishedDetail>
                <Dot />
              </>
            )}
            <PublishedDetail>{formatDate(new Date(data.node.updatedAt))} </PublishedDetail>
            <Dot />
            <PublishedDetail>{data.node.minRead} min read</PublishedDetail>
          </PublishedRow>
          <ContentType>{data.node.contentType}</ContentType>
          <Title onClick={() => navigate(`/insights/${data.node.client}`)}>
            {data.node.title}
            <br />
          </Title>
          <Detail onClick={() => navigate(`/insights/${data.node.client}`)}>{data.node.excerpt}</Detail>
        </div>
        <button
          type="button"
          onClick={() => navigate(`/insights/${data.node.client}`)}
          className="px-6 py-2 text-orange bg-white border-orange border-[2px] w-max rounded-[5px]"
        >
          {' '}
          Read more
        </button>
      </InsightDetail>
    </InsightBox>
  );
};

export default InsightCard;
