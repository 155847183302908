import * as React from 'react';
import { graphql } from 'gatsby';
import Page from '../components/Common/Page';
import InsightsSection from '../components/pages/InsightPage/InsightsSection';
import IndexLayout from '../layouts';

export interface TInsight {
  node: {
    id: string;
    author: string;
    contentType: string;
    excerpt: string;
    updatedAt: string;
    thumbnail: string;
    title: string;
    minRead: number;
    client: string;
  };
}

const insights: React.FC<any> = ({ data }) => (
  <IndexLayout transparent>
    <Page>
      <InsightsSection data={data?.allStrapiInsights?.edges as TInsight[]} />
    </Page>
  </IndexLayout>
);

export default insights;

export const query = graphql`
  query InsightQuery {
    allStrapiInsights {
      edges {
        node {
          id
          author
          children {
            id
          }
          contentType
          excerpt
          updatedAt
          thumbnail
          title
          minRead
          client
        }
      }
    }
  }
`;
