import React, { useState, useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import { TInsight } from '../../../pages/insights';
import { PageHeaderText } from '../../../utils/TextLabel';
import { Button, ButtonType } from '../../Common/Button';
import InsightCard from './InsightCard';

const StyledContainer = styled.section(() => [
  tw`relative z-10 md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto pt-10 pb-12 md:pb-20 md:pt-20 sm:mb-20 md:mb-14 xl:mb-0 flex flex-col`
]);

const Title = styled.h2(() => [tw`flex w-full text-[32px] text-left font-bold text-spaceGray mt-0 mb-8 sm:mb-12`]);

const InsightsContainer = styled.div(() => [
  tw`grid grid-cols-1 lg:grid-cols-2 max-w-full gap-x-[80px] gap-y-4 bg-transparent`
]);

export const Background = styled.div(() => [tw`absolute top-0 h-[785px] w-screen background-color[#E46B25] `]);

interface InsightsSectionProps {
  className?: string;
  data: TInsight[];
}

const InsightsSection: React.FC<InsightsSectionProps> = ({ className, data }) => {
  const [limitInsights, setLimitInsights] = useState(4);
  const [insightsToShow, setInsightsToShow] = useState<TInsight[]>([]);

  useEffect(() => {
    const toShow = data
      .sort((a, b) => b.node.updatedAt.localeCompare(a.node.updatedAt))
      .filter((item, index) => index + 1 <= limitInsights && item);
    setInsightsToShow(toShow);
  }, [data, limitInsights]);

  const insights = insightsToShow;

  return (
    <>
      <StyledContainer className={className}>
        <PageHeaderText>Insights</PageHeaderText>
        <InsightsContainer className="mt-10">
          {insights &&
            insights
              .sort((a, b) => b.node.updatedAt.localeCompare(a.node.updatedAt))
              .map((insight) => <InsightCard key={insight.node.id} data={insight} />)
          }
        </InsightsContainer>
        {limitInsights < data.length && (
          <Button
            type={ButtonType.PRIMARY}
            className="mx-auto px-10"
            onClick={() => setLimitInsights(limitInsights + 4)}
          >
            Show more
          </Button>
        )}
      </StyledContainer>
    </>
  );
};

export default InsightsSection;
